<template>
  <dialog-wrapper ref="dialog"
                  :title="$t('settings')"
                  max-width="1000px"
                  @open="loadData">
    <template v-slot:activator="{open}">
      <v-btn icon
             color="primary"
             small
             @click="open">
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
    </template>
    <v-row dense
           class="mt-2">
      <v-col cols="12"
             class="text--primary text-h6">
        {{$t('orderStateChaneNotifications')}}
      </v-col>
      <v-col cols="12">
        <v-radio-group v-model="model.notificationOnlyOwn"
                       class="mt-0 pt-0"
                       row>
          <v-radio :label="$t('onlyOwnOrders')"
                   :value="true" />
          <v-radio :label="$t('allOrders')"
                   :value="false" />
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <trol-expanded-select v-model="model.notificationStageTypes"
                              item-name="publicName"
                              item-value="id"
                              item-disabled="dummy"
                              :items-url="['stage-types', 'lazy-select']"
                              full-load
                              multiple
                              hide-details />
      </v-col>
      <v-col cols="12"
             class="text--primary text-h6 pt-5">
        <div class="d-inline-block">
          <v-switch v-model="limitNotificationByService"
                    hide-details
                    class="pa-0 ma-0"
                    @click="model.notificationServices = []" />
        </div>
        {{$t('limitNotificationByService')}}:
      </v-col>
      <v-col v-if="limitNotificationByService"
             cols="12">
        <trol-expanded-select v-model="model.notificationServices"
                              item-name="publicName"
                              item-value="id"
                              item-disabled="dummy"
                              :items-url="['services']"
                              full-load
                              multiple
                              hide-details />
      </v-col>
    </v-row>
    <template v-slot:actions="{close}">
      <v-row no-gutters>
        <v-col class="flex-grow-0">
          <v-btn color="success"
                 :loading="saving"
                 @click="onSave">
            {{$t('Save')}}
          </v-btn>
        </v-col>
        <v-col class="px-1" />
        <v-col class="flex-grow-0">
          <v-btn @click="close">
            {{$t('Close')}}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </dialog-wrapper>
</template>

<script>
import TrolExpandedSelect from '@/components/TrolExpandedSelect.vue';

export default {
  name: 'UserSettings',
  components: {TrolExpandedSelect},
  data () {
    return {
      limitNotificationByService: false,
      model: {
        notificationServices: [],
        notificationStageTypes: [],
      },
      saving: false,
    };
  },
  methods: {
    async loadData () {
      const {data}                    = await this.API.get(['notifications', 'subscriptions']);
      this.model                      = data;
      this.limitNotificationByService = !!data.notificationServices.length;
    },
    async onSave () {
      this.saving = true;
      try {
        await this.API.post(['notifications', 'subscriptions'], this.model);
        this.$refs.dialog.onClose();
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
