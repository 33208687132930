import Vue from 'vue';
import Vuex from 'vuex';
import snackbar from '@/store/modules/Snackbar';
import loaders from '@/store/modules/Loaders';
import time from '@/store/modules/Time';
import auth from '@/store/modules/Auth';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: {},
  },
  mutations: {
    setUser (state, user) {
      state.user = user;
    },
  },
  modules: {
    snackbar,
    loaders,
    time,
    auth,
  },
});

export {store};
export default store;
