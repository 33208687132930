// TODO: make filter that will respect format
// import store from 'store';

import i18n from '@/i18n';

const secondsInMin = 60;
const secondsInHr  = 3600;
const secondsInDay = 86400;

function dateShort (time, placeholder = '') {
  return time ? new Date(time).toLocaleDateString() : placeholder; //, store.state.options.shortDateFormat
}

function dateCustom (time, y = 'dd.MM.yyyy', options = {}) {
  if (!time) {
    return '';
  } else {
    time = new Date(options.withTime ? time : time.split('T').shift());

    const z = {
      M: time.getMonth() + 1,
      d: time.getDate(),
      h: time.getHours(),
      m: time.getMinutes(),
      s: time.getSeconds(),
    };

    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? '0' : '') + z[v.slice(-1)]).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
      return time
        .getFullYear()
        .toString()
        .slice(-v.length);
    });
  }
}

function dateTimeShort (time) {
  return dateCustom(time, 'dd.MM.yyyy hh:mm', {withTime: 1});
}

function explodeSeconds (value, withSeconds = false) {
  const days    = Math.floor(value / secondsInDay);
  const hours   = Math.floor(value / secondsInHr % 24);
  const minutes = Math.floor(value / secondsInMin % 60);
  const seconds = Math.floor(value % 60);

  const result = [];

  if (days > 0 || result.length > 0) {
    result.push(days + i18n.t('time.d'));
  }
  if (hours > 0 || result.length > 0) {
    result.push(hours + i18n.t('time.h'));
  }
  if (minutes > 0 || result.length > 0 || !withSeconds) {
    result.push(minutes + i18n.t('time.m'));
  }
  if (withSeconds || (days === 0 && hours === 0 && minutes === 0)) {
    result.push(seconds + i18n.t('time.s'));
  }
  return result.join(' ');
}

export {dateShort, dateCustom, explodeSeconds, dateTimeShort};
