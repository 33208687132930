/* eslint-disable no-undef */
const REDIRECT_URL     = SYSTEM_CONFIG.REDIRECT;
const API_URL          = SYSTEM_CONFIG.API;
const AUTH_URL         = SYSTEM_CONFIG.AUTH;
const MQ_OPTIONS       = SYSTEM_CONFIG.MQ_OPTIONS;
const KEYCLOAK_LIBRARY = SYSTEM_CONFIG.KEYCLOAK_LIBRARY;
const KEYCLOAK_PARAMETERS = SYSTEM_CONFIG.KEYCLOAK_PARAMETERS;

export {
  REDIRECT_URL,
  API_URL,
  AUTH_URL,
  MQ_OPTIONS,
  KEYCLOAK_LIBRARY,
  KEYCLOAK_PARAMETERS,
};
