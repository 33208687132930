import Vue from 'vue';
import TrolDatePicker from '@/components/TrolDatePicker/TrolDatePicker';
import TrolTimePicker from '@/components/TrolDatePicker/TrolTimePicker';
import ConfirmBtn from '@/components/ProgressBtn/ConfirmBtn';
import ProgressBtn from '@/components/ProgressBtn/ProgressBtn';
import DeleteBtn from '@/components/ProgressBtn/DeleteBtn';
import EnabledSwitch from '@/components/EnabledSwitch/EnabledSwitch';
import TrolLazySelect from '@/components/LazySelect/TrolLazySelect';
import TrolTooltipWrapper from '@/components/TrolTooltipWrapper/TrolTooltipWrapper';
import DialogWrapper from '@/components/DialogWrapper';

Vue.component('TrolDatePicker', TrolDatePicker);
Vue.component('TrolTimePicker', TrolTimePicker);
Vue.component('ConfirmBtn', ConfirmBtn);
Vue.component('ProgressBtn', ProgressBtn);
Vue.component('DeleteBtn', DeleteBtn);
Vue.component('EnabledSwitch', EnabledSwitch);
Vue.component('TrolLazySelect', TrolLazySelect);
Vue.component('TrolTooltipWrapper', TrolTooltipWrapper);
Vue.component('DialogWrapper', DialogWrapper);
