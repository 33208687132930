import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    exact: true,
    redirect: '/orders',
  },
  {
    path: '/orders',
    name: 'OrdersList',
    props: true,
    exact: true,
    component: () => import(/* webpackChunkName: "Orders" */ './views/Orders/PccOrderList.vue'),
  },
  {
    path: '/orders/:id/:copyId?',
    name: 'OrderProperties',
    props: true,
    exact: true,
    component: () => import(/* webpackChunkName: "Orders" */ './views/Orders/PccOrderProperties.vue'),
  },
  {
    path: '/track/:orderId',
    name: 'Track',
    props: true,
    exact: true,
    component: () => import(/* webpackChunkName: "Track" */ './views/Track/PccTrackList.vue'),
  },
  {
    path: '/bills',
    name: 'Bills',
    props: true,
    exact: true,
    component: () => import(/* webpackChunkName: "Bills" */ './views/Bills/PccBillList.vue'),
  },
  {
    path: '/booker-documents/:orderId?',
    name: 'BookerDocuments',
    props: true,
    exact: true,
    component: () => import(/* webpackChunkName: "Documents" */ './views/BookerDocuments/PccBookerDocumentList.vue'),
  },
  {
    path: '/manual',
    name: 'Manual',
    exact: true,
    component: () => import(/* webpackChunkName: "Documents" */ './views/Layout/PccManual.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
