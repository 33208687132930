/**
 * Created by Gideon on 20.02.2017.
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import translations from '@/i18n/messages';

VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
  // this === VueI18n instance, so the locale property also exists here
  if (this.locale !== 'ru-RU') {
    return choice > 1 ? 2 : choice;
  }

  if (choice === 0) {
    return 0;
  }

  const teen        = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (!teen && endsWithOne) {
    return 1;
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return (choicesLength < 4) ? 2 : 3;
};

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en-US',
  messages: translations,
});

export default i18n;
