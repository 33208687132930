<template>
  <v-app v-if="appLoading">
    <v-container class="fill-height">
      <v-row align="center"
             justify="center">
        <v-col class="flex-grow-0">
          <v-progress-circular :width="6"
                               :size="100"
                               color="accent"
                               indeterminate />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
  <v-app v-else>
    <v-progress-linear
      v-if="loaders.topProgress"
      style="margin: 0; position: fixed; z-index: 1000"
      indeterminate
      height="2"
      color="accent" />
    <pcc-header />
    <v-main>
      <router-view :key="$route.path" />
    </v-main>
    <v-snackbar v-model="snackbarDisplayed"
                :timeout="snackbar.current.timeout"
                :color="snackbar.current.color"
                :multi-line="snackbar.current.content > 60"
                bottom
                :left="snackbar.current.position==='left'"
                :right="snackbar.current.position==='right'">
      <v-icon v-if="snackbar.current.icon"
              :color="snackbar.current.iconColor"
              class="pr-1">
        {{snackbar.current.icon}}
      </v-icon>
      <template v-if="$te(snackbar.current.content)">
        {{$t(snackbar.current.content, snackbar.current.parameters)}}
      </template>
      <template v-else>
        {{snackbar.current.fallback}}
      </template>
      <template v-slot:action="{attrs}">
        <v-btn text
               dark
               v-bind="attrs"
               @click.native="snackbarDisplayed = false">
          {{$t('Close')}}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>

import {mapState, mapActions} from 'vuex';

import PccHeader from '@/views/Layout/PccHeader';
import {KEYCLOAK_PARAMETERS} from '@/config';

export default {
  name: 'App',
  components: {PccHeader},
  data () {
    return {
      appLoading: true,
    };
  },
  computed: {
    ...mapState({
      snackbar: state => state.snackbar,
      user: state => state.user,
      loaders: state => state.loaders,
    }),
    snackbarDisplayed: {
      get () {
        return this.snackbar.show;
      },
      set (value) {
        if (value) this.showSnackbar();
        else this.hideSnackbar();
      },
    },
  },
  async created () {
    this.startNowTimer();
    await this.initializeApp();
    // this.setUser(
    //   {
    //     "locale": "ru-RU",
    //     "localeShort": "ru",
    //     "id": 655,
    //     "contact": {
    //       "enabled": true,
    //       "id": 13375,
    //       "name": "Khasanov Umar",
    //       "primaryEmail": "umar.khasanov@transportir.com",
    //     },
    //   },
    // );
    this.appLoading = false;
  },
  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/show',
      hideSnackbar: 'snackbar/hide',
      startNowTimer: 'time/start',
    }),
    async initializeApp () {
      /* eslint-disable-next-line no-undef */
      this.$store.commit('auth/setKeycloakInstance', new Keycloak(KEYCLOAK_PARAMETERS));
      await this.$store.state.auth.keycloak.init({
        onLoad: 'login-required',
        promiseType: 'native',
        checkLoginIframe: false,
      });
      const profile = await this.$store.state.auth.keycloak.loadUserInfo();
      this.$store.commit('auth/setKeycloakUser', profile);
      const success = await this.API.get(['system', 'contact']);
      this.$store.commit('auth/setContact', {
        locale: 'ru-RU',
        localeShort: 'ru',
        ...success.data,
      });
      this.isLoading = false;
    },
  },
};
</script>
