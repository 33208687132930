<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="slide-y-transition"
    offset-y
    :nudge-right="40"
    max-width="290px"
    min-width="290px"
    :disabled="disabled">
    <template v-slot:activator="{on}">
      <v-text-field
        v-model="displayDate"
        :label="label"
        append-icon="mdi-calendar"
        :class="inputClass"
        :hide-details="hideDetails"
        :error-messages="errorMessages"
        readonly
        :disabled="disabled"
        v-on="on" />
    </template>
    <v-date-picker v-model="date"
                   :type="type"
                   :allowed-dates="allowedDates"
                   first-day-of-week="1"
                   scrollable
                   actions>
      <v-btn v-if="clearable"
             text
             color="accent"
             @click="clear">
        {{$t('Clear')}}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import {mapState} from 'vuex';
import {dateShort} from '@/filters/dateFormat';

export default {
  name: 'TrolDatePicker',
  props: {
    value: {
      type: [String, Array, Date],
      default: '',
    },
    type: {
      type: String,
      default: 'date',
    },
    allowedDates: {
      type: Function,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    inputClass: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [Array],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapState({
      options: state => state.options,
    }),
    date: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
        this.menu = false;
      },
    },
    displayDate () {
      if (!this.value) return '';
      return this.type === 'date' ? dateShort(this.value) : this.value;
    },
  },
  methods: {
    clear () {
      this.$emit('input', '');
      this.menu = false;
    },
  },
};
</script>

<style>
</style>
