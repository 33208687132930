<template>
  <v-switch v-model="model"
            :color="color"
            hide-details
            :label="label"
            :loading="loading"
            :disabled="loading || disabled"
            :readonly="readonly"
            :class="{'no-label-switch' : noLabel}"
            @change="toggle" />
</template>

<script>
export default {
  name: 'TrolEnabledSwitch',
  props: {
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    route: {
      type: [Array, String],
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: 'success',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    emitResponseObject: {
      type: Boolean,
      default: false,
    },
    property: {
      type: String,
      default: 'enabled',
    },
    method: {
      type: String,
      default: 'post',
    },
  },
  data () {
    return {
      model: this.value,
      cancelingApi: this.API.getCancelingApi(),
      loading: false,
    };
  },
  watch: {
    value: function (value) {
      this.model = value;
    },
  },
  methods: {
    async toggle () {
      if (this.route) {
        try {
          this.loading = true;
          const result = await this.cancelingApi.post(this.route);
          if (result.data) {
            if (this.emitResponseObject) {
              this.$emit('response', result.data);
            } else {
              this.emitChangedState(result.data[this.property]);
            }
          } else {
            if (!this.emitResponseObject) {
              this.emitChangedState(this.model);
            }
          }
        } catch (e) {
        } finally {
          this.loading = false;
          this.model   = this.value;
        }
      } else {
        this.emitChangedState(this.model);
      }
    },
    emitChangedState (value) {
      this.$emit('preToggle', value);
      this.$emit('input', value);
    },
  },
}
;
</script>

<style scoped>
  .trol-data-table-table .v-input,
  .no-label-switch.v-input {
    margin-top: 0;
  }
</style>
