<template>
  <v-row v-if="loading"
         dense>
    <v-col cols="12">
      <v-progress-linear indeterminate
                         color="accent" />
    </v-col>
  </v-row>
  <v-row v-else
         dense>
    <v-col v-for="choice in collection"
           :key="choice[itemValue]"
           cols="12"
           sm="6">
      <v-checkbox v-model="model"
                  :value="choice"
                  color="primary"
                  class="ma-0"
                  :label="choice[itemName]"
                  :value-comparator="comparator"
                  multiple
                  hide-details />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TrolExpandedSelect',
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    itemsUrl: {
      type: [String, Array],
      default: undefined,
    },
    items: {
      type: [Array],
      default: undefined,
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    itemName: {
      type: String,
      default: 'name',
    },
  },
  data () {
    return {
      loading: false,
      cancelingApi: this.API.getCancelingApi(),
      collection: [],
    };
  },
  computed: {
    model: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
  },
  created () {
    if (this.items) {
      this.collection = [...this.items];
    } else {
      this.loadCollection();
    }
  },
  methods: {
    comparator (left, right) {
      return left[this.itemValue] === right[this.itemValue];
    },
    async loadCollection () {
      this.loading = true;
      try {
        const options   = {
          itemsPerPage: 1000,
        };
        const result    = await this.cancelingApi.get(this.itemsUrl, options);
        this.collection = result.data;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass"
       scoped>

</style>
