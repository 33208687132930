import Vue from 'vue';

import {timeAgo} from '@/filters/timeAgo';
import {dateShort, dateCustom, explodeSeconds, dateTimeShort} from '@/filters/dateFormat';
import {finance, id, fileSize} from '@/filters/number';
import {currencySymbol} from '@/filters/currencySymbol';
import {accessor} from '@/filters/accessor';
import {getIntlString} from '@/utils/GetIntlStrings';
import {temperature} from '@/filters/temperature';

Vue.filter('timeAgo', timeAgo);
Vue.filter('dateShort', dateShort);
Vue.filter('dateTimeShort', dateTimeShort);
Vue.filter('dateCustom', dateCustom);
Vue.filter('finance', finance);
Vue.filter('id', id);
Vue.filter('fileSize', fileSize);
Vue.filter('currencySymbol', currencySymbol);
Vue.filter('explodeSeconds', explodeSeconds);
Vue.filter('accessor', accessor);
Vue.filter('getIntlString', getIntlString);
Vue.filter('temperature', temperature);
