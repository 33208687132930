<template>
  <div>
    <v-app-bar fixed
               app
               class="header">
      <v-row align="start"
             class="fill-height"
             no-gutters>
        <v-col class="flex-grow-0">
          <router-link :to="{name: 'OrdersList'}"
                       class="-brand">
            <transportir-logo />
          </router-link>
        </v-col>
        <v-spacer />
        <v-col class="flex-grow-0 text-no-wrap">
          <div class="text-right">
            <v-chip small
                    color="primary"
                    class="mr-2">
              {{$vuetify.breakpoint.name}}
            </v-chip>
            <v-btn href="/pcc_user_manual.pdf"
                   color="primary"
                   small
                   icon>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
            <user-settings />
            <v-btn color="primary"
                   small
                   icon
                   @click="$store.state.auth.keycloak.logout()">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </div>
          <div class="text-right text--secondary body-1">
            {{$store.state.auth.contact.name}}
          </div>
        </v-col>
      </v-row>
      <template v-slot:extension>
        <v-row class="primary pl-4"
               no-gutters
               align="center">
          <v-col cols="12"
                 sm="3"
                 class="py-1">
            <v-row no-gutters>
              <v-col>
                <v-text-field v-model="trackId"
                              placeholder="Track & trace"
                              hide-details
                              single-line
                              solo-inverted
                              dark
                              dense
                              @keypress.enter="onTrackSearchClick" />
              </v-col>
              <v-col class="flex-grow-0 px-1">
                <v-btn dark
                       icon
                       @click="onTrackSearchClick">
                  <v-icon>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-tabs right
                    dark
                    show-arrows
                    background-color="primary">
              <v-tab :to="{name: 'OrdersList'}">
                {{$t('All requests')}}
              </v-tab>
              <v-tab :to="{name: 'Bills'}">
                {{$t('Bills to pay')}}
              </v-tab>
              <v-tab :to="{name: 'BookerDocuments'}">
                {{$t('Booker docs')}}
              </v-tab>
              <v-tab :to="{name: 'OrderProperties', params:{id: 'new'}}"
                     class="accent">
                {{$t('newOrder')}}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import TransportirLogo from '@/components/TransportirLogo';
import UserSettings from '@/views/Layout/UserSettings.vue';

export default {
  name: 'PccHeader',
  components: {UserSettings, TransportirLogo},
  data () {
    return {
      trackId: '',
    };
  },
  computed: {
    isDev () {
      return process.env.NODE_ENV === 'development';
    },
  },
  methods: {
    onTrackSearchClick () {
      if (!this.trackId || (this.$route.name === 'Track' && Number.parseInt(this.$route.params.orderId) === Number.parseInt(this.trackId))) {
        return;
      }
      this.trackId = Number.parseInt(this.trackId);
      this.$router.push({name: 'Track', params: {orderId: this.trackId}});
    },
  },
};
</script>

<style scoped
       lang="sass">
.header::v-deep
  .v-toolbar__extension
    padding: 0

  .v-btn
    height: 36px !important
    width: 36px !important
</style>
