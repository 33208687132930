import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store';
import isUserReadOnly from './plugins/IsUserReadOnly';
import vuetify from './plugins/vuetify';
import GetValue from './plugins/GetValue';
import '@/filters.js';
import API from '@/services/Api.js';
import i18n from '@/i18n';
import VeeValidate from 'vee-validate';
import '@mdi/font/css/materialdesignicons.css';
import '@/plugins/ServerValidationParser';
import '@/plugins/IsGranted';
import '@/global-components';
import {KEYCLOAK_LIBRARY} from '@/config';
import '@/Styles/main.sass';

Vue.config.productionTip = false;
Vue.prototype.API        = API;
i18n.locale              = 'ru-RU';

Vue.use(VeeValidate, {
  i18n: i18n,
  i18nRootKey: 'validation',
});
Vue.use(isUserReadOnly);
Vue.use(GetValue);

const script  = document.createElement('script');
script.async  = false;
script.defer  = false;
script.type   = 'text/javascript';
script.src    = KEYCLOAK_LIBRARY;
script.onload = async () => {
  new Vue({
    router,
    i18n,
    store,
    vuetify,
    render: h => h(App),
  }).$mount('#app');
};
document.head.appendChild(script);
