<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="slide-y-transition"
    offset-y
    max-width="290px"
    min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="time"
        :label="label"
        :class="inputClass"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        append-icon="mdi-clock-outline"
        readonly
        v-on="on" />
    </template>
    <v-time-picker v-if="menu"
                   v-model="time"
                   :format="format"
                   full-width
                   @click:minute="$refs.menu.save(time)">
      <v-btn v-if="clearable"
             text
             color="accent"
             @click="clear">
        {{$t('Clear')}}
      </v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'TrolTimePicker',
  props: {
    value: {
      type: [String, Array, Date],
      default: undefined,
    },
    format: {
      type: String,
      default: '24hr',
    },
    label: {
      type: String,
      default: undefined,
    },
    inputClass: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [Array, Object],
      default: undefined,
    },
  },
  data () {
    return {
      menu: false,
    };
  },
  computed: {
    time: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onChange (value) {
      this.$emit('input', value);
      this.menu = false;
    },
    clear () {
      this.$emit('input', undefined);
      this.menu = false;
    },
  },
};
</script>

<style>
</style>
