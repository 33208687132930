<template>
  <v-dialog v-model="open"
            :max-width="maxWidth"
            :persistent="!notPersistent"
            :scrollable="!notScrollable">
    <template v-slot:activator="_scope">
      <slot name="activator"
            :open="onOpen" />
    </template>
    <v-card>
      <v-toolbar dense
                 color="toolbar-bg">
        <v-toolbar-title>
          <slot name="title">
            {{title}}
          </slot>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon
               @click="onClose">
          <v-icon color="accent">
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-2">
        <slot />
      </v-card-text>
      <v-card-actions class="pt-0">
        <slot name="actions"
              :close="onClose" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogWrapper',
  props: {
    notPersistent: {
      type: Boolean,
      default: false,
    },
    notScrollable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: undefined,
    },
    maxWidth: {
      type: String,
      default: '600px',
    },
  },
  data () {
    return {
      open: false,
    };
  },
  methods: {
    onOpen () {
      this.open = true;
      this.$emit('open');
    },
    onClose () {
      this.open = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="sass"
       scoped>

</style>
