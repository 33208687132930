import store from '@/store';

function isUserReadOnly () {
  return !store.getters['auth/isGranted']('ROLE_CAN_CHANGE_DATA');
}

export default {
  install: function (Vue) {
    Vue.prototype.$isUserReadOnly = isUserReadOnly;
  },
};
