import JavascriptTimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import ru from 'javascript-time-ago/locale/ru';
import getDateDiff from '@/utils/DateDiff';
import {dateShort} from '@/filters/dateFormat';

// Add locale-specific relative date/time formatting rules.
JavascriptTimeAgo.locale(en);
JavascriptTimeAgo.locale(ru);

export function timeAgo (time, current, cutoffDays = 3) {
  let result;

  if (!cutoffDays || getDateDiff(time, new Date(), 'days') > -cutoffDays) {
    const timeAgo = new JavascriptTimeAgo('ru-RU');
    result        = timeAgo.format(new Date(time));
  } else {
    result = dateShort(time);
  }

  return result;
}
