<template>
  <v-select v-if="noAutocomplete"
            ref="select"
            v-model="model"
            :item-text="itemText"
            :item-value="itemValue"
            :item-disabled="itemDisabled"
            :items="computedItems()"
            :label="label"
            :loading="loading"
            :hide-details="hideDetails"
            :clearable="clearable"
            :return-object="returnObject"
            :multiple="multiple"
            :solo="solo"
            :cache-items="multiple"
            :error-messages="errorMessages"
            :prefix="prefix"
            :disabled="disabled"
            :color="color"
            :placeholder="placeholder"
            :dense="dense"
            :hint="hint"
            :persistent-hint="persistentHint">
    <template v-for="(_, slot) of $scopedSlots"
              v-slot:[slot]="scope">
      <slot :name="slot"
            v-bind="scope" />
    </template>
    <template v-if="!showDisabled && enabledItemsUrl"
              v-slot:prepend-item>
      <v-btn small
             block
             text
             @click="onShowDisabled()">
        - {{$t('Show disabled')}} -
      </v-btn>
    </template>
    <template v-if="lastLoadedCount > 0 && lastLoadedCount === ITEMS_PER_PAGE && !fullLoad"
              v-slot:append-item>
      <v-btn small
             block
             text
             @click="nextPage()">
        - {{$t('Load more')}} -
      </v-btn>
    </template>
  </v-select>
  <v-autocomplete v-else
                  ref="select"
                  v-model="model"
                  :item-text="itemText"
                  :item-value="itemValue"
                  :item-disabled="itemDisabled"
                  :items="computedItems()"
                  :label="label"
                  :loading="loading"
                  :hide-details="hideDetails"
                  :clearable="clearable"
                  :return-object="returnObject"
                  :multiple="multiple"
                  :solo="solo"
                  :cache-items="multiple"
                  :error-messages="errorMessages"
                  :prefix="prefix"
                  :disabled="disabled"
                  :color="color"
                  :search-input.sync="search"
                  :placeholder="placeholder"
                  autocomplete="off"
                  :dense="dense"
                  :hint="hint"
                  :persistent-hint="persistentHint">
    <template v-for="(_, slot) of $scopedSlots"
              v-slot:[slot]="scope">
      <slot :name="slot"
            v-bind="scope" />
    </template>
    <template v-if="!showDisabled && enabledItemsUrl"
              v-slot:prepend-item>
      <v-btn small
             block
             text
             @click="onShowDisabled()">
        - {{$t('Show disabled')}} -
      </v-btn>
    </template>
    <template v-if="lastLoadedCount > 0 && lastLoadedCount === ITEMS_PER_PAGE && !fullLoad"
              v-slot:append-item>
      <v-btn small
             block
             text
             @click="nextPage()">
        - {{$t('Load more')}} -
      </v-btn>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from 'debounce';

const ITEMS_PER_PAGE           = 40;
const FULL_LOAD_ITEMS_PER_PAGE = 1000;

export default {
  name: 'TrolLazySelect',
  props: {
    value: {
      type: [String, Object, Number, Array],
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    itemText: {
      type: [String, Function],
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    itemValue: {
      type: [String, Function],
      default: undefined,
    },
    itemDisabled: {
      type: String,
      default: undefined,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    enabledItemsUrl: {
      type: [String, Array],
      default: undefined,
    },
    itemsUrl: {
      type: [String, Array],
      default: undefined,
    },
    selectedUrl: {
      type: [String, Array],
      default: undefined,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    fullLoad: {
      type: Boolean,
      default: false,
    },
    noAutocomplete: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noLoad: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [Array, Object, String],
      default: undefined,
    },
    prefix: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: undefined,
    },
    cached: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      ITEMS_PER_PAGE,
      items: [],
      loading: false,
      search: null,
      cancelingApi: this.API.getCancelingApi(),
      lastSearch: undefined,
      lastLoadedCount: -1,
      page: 0,
      showDisabled: false,
    };
  },
  computed: {
    computedItemsUrl () {
      return this.enabledItemsUrl && !this.showDisabled
        ? this.enabledItemsUrl
        : this.itemsUrl;
    },
    model: {
      get () {
        return this.value;
      },
      set (val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    search (newVal, oldVal) {
      if (newVal !== oldVal && this.lastSearch !== newVal && !this.fullLoad) {
        this.lastSearch = newVal;
        this.debouncedNewItems(newVal);
      }
    },
    itemsUrl (newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.showDisabled = false;
        this.updateByUrl();
      }
    },
    enabledItemsUrl (newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.updateByUrl();
      }
    },
  },
  created () {
    if (
      typeof this.value === 'undefined'
      || this.value === null
      || this.value === ''
      || this.fullLoad
      || (Array.isArray(this.value) && this.value.length === 0)
    ) {
      this.newItems();
    } else {
      if (!this.returnObject && !this.fullLoad) {
        this.loadSelected();
      }
    }
  },
  methods: {
    computedItems () {
      return (!Array.isArray(this.items) || !this.items.length) && this.value && this.returnObject
        ? [this.value]
        : this.items;
    },
    onShowDisabled () {
      this.showDisabled = true;
      this.updateByUrl();
    },
    updateByUrl: debounce(async function () {
      this.items = await this.loadData(this.lastSearch);
    }, 50),
    async loadData (term) {
      if (this.computedItemsUrl === undefined || this.noLoad) return [];

      const options = {
        itemsPerPage: this.fullLoad ? FULL_LOAD_ITEMS_PER_PAGE : ITEMS_PER_PAGE,
        term: term,
        page: this.page,
      };

      if (this.cached && !this.$store.getters['cache/has'](this.computedItemsUrl, options)) {
        this.$store.commit('cache/populate', {
          path: this.computedItemsUrl,
          params: options,
          data: [],
        });
      }

      this.loading         = true;
      const result         = await this.cancelingApi.get(this.computedItemsUrl, options);
      this.loading         = false;
      this.lastLoadedCount = result.data.length;

      if (this.cached && !this.$store.getters['cache/has'](this.computedItemsUrl, options)) {
        this.$store.commit('cache/populate', {
          path: this.computedItemsUrl,
          params: options,
          data: result.data,
        });
      }

      return result.data;
    },
    async newItems (term) {
      this.page       = 0;
      this.lastSearch = term;
      this.items      = await this.loadData(term);
    },
    debouncedNewItems: debounce(function (term) {
      this.newItems(term);
    }, 500),
    async loadSelected () {
      this.loading = true;
      let id;
      if (Array.isArray(this.value)) {
        id = this.value.join('-');
      } else {
        id = this.value;
      }
      const url    = this.selectedUrl.replace('{' + this.itemValue + '}', id);
      const result = await this.API.get(url);
      if (Array.isArray(result.data)) {
        this.items = result.data;
      } else {
        this.items = [result.data];
      }

      this.loading = false;
    },
    async nextPage () {
      this.page++;
      this.items.push(...await this.loadData(this.lastSearch));
      this.$refs.select.lastItem += 20;
    },
  },
};
</script>

<style scoped>
  .v-autocomplete::v-deep .v-select__selections > input {
    width: 100%;
    flex: none !important;
  }
</style>
