import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    // dark: store.state.options.darkTheme,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#012169',
        secondary: '#4d4b5b',
        accent: '#fe5000',
        'toolbar-bg': '#f5f5f5',
      },
      // dark: {
      //   primary: '#297fff',
      //   secondary: '#4d4b5b',
      //   accent: '#fb7a78',
      //   'toolbar-bg': '#212121',
      // },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
  // lang: {
  //   t: (key, ...params) => i18n.t(key, params),
  // },
});
