/**
 * Created by Gideon on 08.03.2017.
 */

import RU from './ru_RU';
import EN from './en_US';

export default {
  'ru-RU': RU,
  'en-US': EN,
};
