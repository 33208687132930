function temperature (value, accuracy = 0) {
  let result = Number(value);

  if (result > 0) {
    result = '+' + result;
  }

  result = result.toLocaleString(undefined, {
    minimumFractionDigits: accuracy,
    maximumFractionDigits: accuracy,
    useGrouping: false,
  }) + '℃';

  return result;
}

export {temperature};
