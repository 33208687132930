<template>
  <trol-tooltip-wrapper top>
    <template v-slot:activator="{on}">
      <progress-btn :dark="dark"
                    :icon="icon"
                    :block="block"
                    :fab="fab"
                    :outlined="outlined"
                    :rounded="rounded"
                    :loading="loading"
                    :color="color"
                    :class="buttonClass"
                    :show-progress="countdown"
                    :progress="value"
                    :progress-color="progressColor"
                    :ripple="false"
                    :small="small"
                    :x-small="xSmall"
                    :text="text"
                    :disabled="disabled"
                    v-on="on"
                    @touchstart.native="countdownStart"
                    @touchend.native="countdownStop"
                    @mouseup="countdownStop"
                    @mousedown="countdownStart"
                    @mouseleave="countdownStop">
        <slot />
      </progress-btn>
    </template>
    <template v-if="tooltip">
      {{tooltip}}<br>
    </template>
    <div class="warning--text">
      {{defaultTooltip}}
    </div>
  </trol-tooltip-wrapper>
</template>

<script>
export default {
  name: 'ConfirmBtn',
  props: {
    delay: {
      type: Number,
      default: 5,
    },
    tooltip: {
      type: String,
      default: undefined,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: undefined,
    },
    progressColor: {
      type: String,
      default: undefined,
    },
    text: {
      type: Boolean,
      default: false,
    },
    btnClass: {
      type: Object,
      default () {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      countdown: false,
      interval: undefined,
      defaultTooltip: this.$t('Hold to confirm action'),
      value: 0,
    };
  },
  computed: {
    buttonClass () {
      return {
        ...this.btnClass,
        countdown: this.countdown,
      };
    },
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  methods: {
    countdownStart () {
      if (this.countdown) return;
      this.countdown = true;
      this.interval  = setInterval(() => {
        this.value += 100 / this.delay;
        if (this.value > 100) {
          this.$emit('confirmed');
          this.countdownStop();
        }
      }, 500);
    },
    countdownStop () {
      this.countdown = false;
      this.value     = 0;
      clearInterval(this.interval);
    },
  },
};
</script>

<style scoped
       lang="sass">

</style>
