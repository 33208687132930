import intersection from 'lodash/intersection';

const state = {
  keycloak: undefined,
  keycloakUser: {},
  contact: {},
};

const mutations = {
  setKeycloakInstance: (state, data) => {
    state.keycloak = data;
  },
  setKeycloakUser: (state, data) => {
    state.keycloakUser = data;
    if (!state.keycloakUser.realmRoles) {
      state.keycloakUser.realmRoles = [];
    }
    if (!state.keycloakUser.clientRoles) {
      state.keycloakUser.clientRoles = [];
    }
  },
  setContact: (state, data) => {
    state.contact = data;
  },
};

const actions = {};

const getters = {
  keycloak: state => {
    return state.keycloak;
  },
  isGranted: state => {
    return (roles) => {
      let collection;
      if (Array.isArray(roles)) {
        collection = roles;
      } else {
        collection = roles === undefined ? [] : [roles];
      }
      return collection.length === 0
        || intersection(state.keycloakUser.realmRoles, collection).length > 0
        || intersection(state.keycloakUser.clientRoles, collection).length > 0;
    };
  },
  // getUser: state => {
  //   return state.user;
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
