<template>
  <confirm-btn color="error"
               :loading="loading"
               :text="text"
               :icon="icon"
               :fab="fab"
               :rounded="rounded"
               :outlined="outlined"
               :dark="dark"
               :btn-class="btnClass"
               :small="small"
               :block="block"
               @confirmed="onDelete">
    <v-icon :small="smallIcon">
      mdi-delete-forever
    </v-icon>
  </confirm-btn>
</template>

<script>
import {getValue} from '@/utils/PathAccessor';

export default {
  name: 'DeleteBtn',
  props: {
    route: {
      type: [String, Array],
      required: true,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    smallIcon: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: true,
    },
    btnClass: {
      type: Object,
      default () {
        return {};
      },
    },
  },
  data () {
    return {
      loading: false,
    };
  },
  methods: {
    async onDelete () {
      this.loading = true;
      try {
        const response = await this.API.delete(this.route);
        this.$store.dispatch('snackbar/add', {
          color: 'warning', content: 'Deleted',
        });
        this.$emit('deleted', getValue(response, 'data'));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>
