<template>
  <v-tooltip :top="top"
             :right="right"
             :left="left"
             :bottom="bottom"
             :open-delay="openDelay">
    <template v-for="(_, slot) of $scopedSlots"
              v-slot:[slot]="scope">
      <slot :name="slot"
            v-bind="scope" />
    </template>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TrolTooltipWrapper',
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    openDelay: {
      type: Number,
      default: 100,
    },
  },
  data () {
    return {};
  },
};
</script>

<style lang="sass"
       scoped>

</style>
