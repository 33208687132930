<template>
  <v-btn
    :dark="dark"
    :icon="icon"
    :block="block"
    :fab="fab"
    :outlined="outlined"
    :rounded="rounded"
    :loading="loading"
    class="progress-btn"
    :color="showProgressComputed && !(outlined && !(icon || fab)) ? 'white' : color"
    :class="{'progress':showProgressComputed}"
    :small="small"
    :x-small="xSmall"
    :text="text"
    :ripple="ripple"
    :disabled="disabled"
    @mouseenter="emitMouseenter"
    @mouseleave="emitMouseleave"
    @click="emitClick"
    @mouseup="emitMouseup"
    @mousedown="emitMousedown">
    <template v-if="!(fab || icon)">
      <v-progress-linear
        v-if="showProgressComputed"
        :value="progress"
        :color="progressColor ? progressColor : color"
        height="100%"
        class="internal-linear-progress" />
      <div class="internal-slot"
           :class="{'white--text' : showProgressComputed }">
        <slot />
      </div>
    </template>
    <template v-else>
      <v-progress-circular
        v-if="showProgressComputed"
        :value="progress"
        :color="progressColor ? progressColor : color"
        :width="3"
        class="internal-circular-progress" />
      <div class="internal-circular-progress-slot"
           :class="circularProgressSlotClass">
        <slot />
      </div>
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'ProgressBtn',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    ripple: {
      type: Boolean,
      default: true,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: undefined,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: undefined,
    },
    progressColor: {
      type: String,
      default: undefined,
    },
    text: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {};
  },
  computed: {
    showProgressComputed () {
      return this.progress !== 0 || this.showProgress;
    },
    circularProgressSlotClass () {
      const result = {};
      if (
        this.color !== undefined
        && (this.icon || this.fab)
        && (this.text || this.showProgressComputed)
      ) {
        result[this.color + '--text'] = true;
      }
      return result;
    },
  },
  methods: {
    emitMousedown (event) {
      this.$emit('mousedown', event);
    },
    emitMouseup (event) {
      this.$emit('mouseup', event);
    },
    emitMouseenter (event) {
      this.$emit('mouseenter', event);
    },
    emitMouseleave (event) {
      this.$emit('mouseleave', event);
    },
    emitClick (event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style scoped
       lang="sass">
  .v-btn
    padding: 0
    overflow: hidden

    &::v-deep .v-btn__content
      position: initial

    .v-btn--floating
      &::v-deep
        .v-btn__content:not(:only-child), .internal-circular-progress-slot
          transform: none
          opacity: 1
          height: 100%
          width: 100%

    .v-btn--floating:active
      &::v-deep .v-btn__content
        .internal-circular-progress-slot
          margin-top: 0 !important

    .internal-linear-progress
      position: absolute
      z-index: 0
      margin: 0

    .internal-circular-progress
      height: 100% !important
      width: 100% !important
      position: absolute
      z-index: 0

    .internal-slot,
    .internal-circular-progress-slot
      z-index: 1

    .internal-slot
      padding-left: 8px
      padding-right: 8px

    .internal-circular-progress-slot
      margin-top: 2px

</style>
